<template>
  <v-dialog
    v-model="addNewClientDialog"
    persistent
    max-width="600px"
  >
    <v-card>
      <DialogHeader icon="mdi-face" text="Add New Client"></DialogHeader>

      <v-card-text>
        <v-form ref="newClientForm" v-model="newClientFormValid">
          <v-alert
            v-if="addNewClientError"
            dense
            text
            type="error"
          >
            {{addNewClientError}}
          </v-alert>

          <v-text-field
            label="Client Name"
            placeholder="Provide new client name"
            v-model="newClientName"
            :rules="formRules.clientNameRules"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-toolbar>
        <v-btn text @click="onCloseNewClientDialog">
          Cancel
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          color="primary"
          type="submit"
          @click.prevent="onCreateNewClient"
        >Create</v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { ClientTypes, CLIENT_STORE } from '@/store/client';
import { ref, reactive } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import DialogHeader from '@/components/DialogHeader.vue';

const { useState, useActions } = createNamespacedHelpers(CLIENT_STORE);

export default {
  name: 'NewClientDialog',
  components: { DialogHeader },
  setup() {
    const addNewClientDialog = ref();
    const newClientForm = ref(null);
    const newClientName = ref('');
    const newClientFormValid = ref(false);
    const formRules = reactive({
      clientNameRules: [
        (v) => !!v || 'Client name is required',
        (v) => {
          const pattern = /^[a-z-]{0,20}$/;
          return pattern.test(v) || 'Client name should only contain lowercase letters and - symbol and have 20 symbols max length';
        },
      ],
    });

    const { addNewClientError } = useState([ClientTypes.state.addNewClientError]);
    const { createNewClient, removeNewClientError } = useActions([
      ClientTypes.actions.createNewClient,
      ClientTypes.actions.removeNewClientError,
    ]);

    function openDialog() {
      addNewClientDialog.value = true;
    }

    function onCloseNewClientDialog() {
      addNewClientDialog.value = false;
      newClientName.value = null;
      newClientFormValid.value = false;
      newClientForm.value.reset();
      removeNewClientError();
    }

    async function onCreateNewClient() {
      newClientForm.value.validate();
      if (newClientFormValid.value) {
        const result = await createNewClient({ client: newClientName.value });
        if (result === ClientTypes.notifications.CREATE_NEW_CLIENT_SUCCESS) {
          onCloseNewClientDialog();
        }
      }
    }

    return {
      addNewClientDialog,
      newClientForm,
      newClientFormValid,
      newClientName,
      formRules,
      addNewClientError,
      openDialog,
      onCloseNewClientDialog,
      onCreateNewClient,
    };
  },

};
</script>
