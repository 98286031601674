<template>
  <v-dialog
    v-model="clientCredentialsDialog"
    persistent
    max-width="400px"
  >
    <v-card class="client-credentials-card">
      <DialogHeader icon="mdi-lock" text="Client Credentials"></DialogHeader>

      <v-card-text>
        <v-list-item two-line class="no-padding">
          <v-list-item-content>
            <v-list-item-title>Access Key</v-list-item-title>
            <v-list-item-subtitle>{{ accessKey }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line class="no-padding">
          <v-list-item-content>
            <v-list-item-title>Secret Key</v-list-item-title>
            <v-list-item-subtitle>{{ secretKey }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>

      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn text @click="onCloseDialog">
          Close
        </v-btn>
      </v-toolbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api';
import { CredentialsTypes, CREDENTIALS_STORE } from '@/store/credentials';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import DialogHeader from '@/components/DialogHeader.vue';

const { useActions } = createNamespacedHelpers(CREDENTIALS_STORE);

export default {
  name: 'ClientCredentialsDialog',
  components: { DialogHeader },
  setup() {
    const clientCredentialsDialog = ref();
    const accessKey = ref(null);
    const secretKey = ref(null);

    const { request } = useActions([CredentialsTypes.actions.request]);

    async function openDialog(client) {
      const credentials = await request(client);
      accessKey.value = credentials.accessKey;
      secretKey.value = credentials.secretKey;
      clientCredentialsDialog.value = true;
    }

    function onCloseDialog() {
      clientCredentialsDialog.value = false;
    }

    return {
      clientCredentialsDialog,
      onCloseDialog,
      openDialog,
      accessKey,
      secretKey,
    };
  },
};
</script>

<style lang="scss" scoped>
  .client-credentials-card {
    .no-padding {
      padding: 0;
    }
  }
</style>
