<template>
  <div class="home-container">
    <div class="actions-container">
      <v-btn
        depressed
        color="primary"
        @click.stop="onOpenDialog()"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Add New Client
      </v-btn>
    </div>

    <ClientGrid></ClientGrid>
    <NewClientDialog ref="clientDialog"></NewClientDialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

import ClientGrid from '@/components/ClientGrid.vue';
import NewClientDialog from '@/components/NewClientDialog.vue';

export default {
  name: 'Clients',
  components: { ClientGrid, NewClientDialog },
  setup() {
    const clientDialog = ref();

    function onOpenDialog() {
      clientDialog.value.openDialog();
    }

    return {
      onOpenDialog,
      clientDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
  .home-container {
    width: 100%;

    .actions-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .dialog-actions {
    padding: 0;
  }
</style>
