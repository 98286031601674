import { render, staticRenderFns } from "./ClientCredentialsDialog.vue?vue&type=template&id=22f46e08&scoped=true&"
import script from "./ClientCredentialsDialog.vue?vue&type=script&lang=js&"
export * from "./ClientCredentialsDialog.vue?vue&type=script&lang=js&"
import style0 from "./ClientCredentialsDialog.vue?vue&type=style&index=0&id=22f46e08&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f46e08",
  null
  
)

export default component.exports