<template>
  <v-toolbar dark>
    <div class="dialog-header">
      <v-icon>{{ icon }}</v-icon>
      <div>{{ text }}</div>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  name: 'DialogHeader',
  props: ['icon', 'text'],
};
</script>
