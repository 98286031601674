<template>
  <div class="grid-container">
    <v-alert
      v-if="clientsLoadError"
      dense
      text
      type="error"
    >
      {{clientsLoadError}}
    </v-alert>

    <div class="grid-header">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Type client name to search..."
        single-line
        hide-details
        solo
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="clients"
      :search="search"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item.resultState="{ item }">
        <v-chip
          :color="getStatusColor(item.resultState)"
          dark
        >
          {{ item.resultState }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="openCredentialsDialog(item.client)"
        >
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>

    <ClientCredentialsDialog ref="credentialsDialog"></ClientCredentialsDialog>
  </div>
</template>

<script>
import { ClientTypes, CLIENT_STORE } from '@/store/client';
import { onBeforeUnmount, ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';

import ClientCredentialsDialog from '@/components/ClientCredentialsDialog.vue';

const { useState, useActions } = createNamespacedHelpers(CLIENT_STORE);

export default {
  name: 'ClientGrid',
  components: { ClientCredentialsDialog },
  setup() {
    const search = ref();
    const credentialsDialog = ref();
    const headers = [
      {
        text: 'Client name',
        align: 'start',
        value: 'client',
      },
      { text: 'Bucket Name', value: 'bucketName', filterable: false },
      { text: 'Table Name', value: 'tableName', filterable: false },
      { text: 'Status', value: 'resultState', filterable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const { loadClients } = useActions([ClientTypes.actions.loadClients]);
    const { clients, clientsLoadError } = useState([
      ClientTypes.state.clients,
      ClientTypes.state.clientsLoadError,
    ]);

    loadClients();

    const interval = setInterval(() => loadClients(), 60 * 1000);

    onBeforeUnmount(() => {
      clearInterval(interval);
    });

    function getStatusColor(status) {
      switch (status) {
        case 'IN_PROGRESS':
          return 'info';
        case 'SUCCEED':
          return 'success';
        default:
          return 'error';
      }
    }

    function openCredentialsDialog(client) {
      credentialsDialog.value.openDialog(client);
    }

    return {
      search,
      headers,
      clients,
      clientsLoadError,
      getStatusColor,
      credentialsDialog,
      openCredentialsDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
  .grid-container {
    .grid-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      .grid-action {
        margin-left: 16px;
      }
    }
  }
</style>
